.construcation {
    width: 100%;
    height: auto;
    margin: 50px 0px 30px;
}
.construcation img{
    width: 100%;
    height: 100%;
}
.construcation-title {
    font-size: 22px;
    text-align: center;
    color: #030303;
    font-weight: 700;
    margin-bottom: 15px;
}
.construcation-contain{
    font-size: 18px;
    text-align: center;
    color: #b0b0b0;
    font-weight: 300;
    padding: 0px 40px;
    margin-bottom: 30px;
    text-shadow: 1px 1px 0px #ccc
}
.construcation-button{
    text-align: center;
}
.construcation-button button{
    padding: 6px 30px;
    outline: none;
    background: none;
    color: #ea8d61;
    border: 1px solid #eba17e;
    border-radius: 15px;
}
.back-img{
    margin: 15px 0px 0px 20px;
}
.swiper_top_back_btn{
    width: 30px !important;
}