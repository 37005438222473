.swiper-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  zoom: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}