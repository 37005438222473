.card-container {
  display: grid;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
}

.card-container::before{
  content: "";
  padding-bottom: 100%;
  display: block;
}

.card-container::before, img{
  grid-area: 1/1/2/2;
}

.card-container .text-container {
  width: 100%;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  z-index: 1000000000;
}

.card-container .text-container h1 {
  font-size: 1.4rem;
}

.card-container .dish-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
