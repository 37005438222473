.cart-wrapper {
  width: 100% !important;
  padding-bottom: 0 !important;
}

.cart-wrapper div.food-accordian {
  margin: 20px;
}

.cart-wrapper div.cart-top-bar {
  margin-left: 50px;
}

.cart-wrapper section.section-food-accordian {
  padding-top: 70px;
  padding-bottom: 0 !important;
}

.cart-wrapper section.section-food-accordian div.food-accordian p.cart-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #670000;
  margin: 10px 0px;
}

.cart-wrapper p.order-details {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #670000;
  margin: 20px;
}
.cart-wrapper section.section-food-accordian .browseDishMain .browseLogo {
  min-height: 350px;
  background: #ffac54;
  display: flex;
  align-items: end;
  padding: 0px 10px 35px 10px;
}
.cart-wrapper section.section-food-accordian .browseDishMain .browseDetail {
  text-align: center;
  padding: 85px 10px 125px 10px;
}
.cart-wrapper section.section-food-accordian .browseDishMain .browseDetail h5 {
  font-size: 20px;
  color: #670000;
  margin-bottom: 20px;
}
.cart-wrapper section.section-food-accordian .browseDishMain .browseDetail p {
  color: #979797;
  font-size: 14px;
  font-weight: 600;
}
.cart-wrapper
  section.section-food-accordian
  .browseDishMain
  .browseDetail
  button {
  border: 1px solid #e8814d;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e8814d;
  font-size: 12px;
  font-weight: bold;
  background: transparent;
  min-width: 175px;
  padding: 6px 0px;
  margin: 20px auto 0px auto;
}
.cart-wrapper
  section.section-food-accordian
  .browseDishMain
  .browseDetail
  button
  img {
  margin-right: 8px;
}
@keyframes spinner-border {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cart-option.active {
  position: fixed;
  bottom: 0px !important;
  z-index: 99999997;
}
.cart-option {
  /* position: relative; */
  width: 100%;
  max-width: 550px;
  height: 70px;
  z-index: 2;
}
.cart-option .cart-option-detail {
  width: 100%;
  background-color: #7d9a6f;
  padding: 16px 15px 0 15px;
  height: 100%;
  margin-bottom: 68px;
}
.cart-option .cart-option-detail .footer-cart-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row !important;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-bottom: 0;
}
.cart-option .cart-option-detail .footer-cart-list .cart-list-item-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  max-width: 60%;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-left
  .cart-total-items:after {
  margin-right: 10px;
  margin-left: 5px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-left
  .cart-total-items
  a.cart-items {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-left
  .cart-total-items
  a.cart-items
  span:first-child {
  margin-right: 3px;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-left
  .cart-list-item-total {
  display: inline-block;
  position: relative;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-left
  .cart-list-item-total
  span {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.cart-option .cart-option-detail .footer-cart-list .cart-list-item-right {
  margin-left: auto;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-right
  .menu-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-right
  .menu-link
  span {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  font-weight: 800;
  margin-right: 15px;
}
.cart-option
  .cart-option-detail
  .footer-cart-list
  .cart-list-item-right
  .menu-link
  img {
  height: 18px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.no-pointer {
  pointer-events: none;
}
.cart-items span {
  margin-right: 10px !important;
}
.billing-section {
  width: 100%;
  margin-bottom: 100px;
  padding-bottom:40px ;
}
.checkout-container .billing-section {
  width: 100%;
  margin-bottom: 0;
  padding-bottom:0;
  margin-top: 20px;
}
.billing-title {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #670000;
  margin: 10px 15px;
  text-transform: capitalize;
}
.price-box {
  margin: 0 20px 10px 16px;
}
.price-box .items__first {
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.price-box .items__total {
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  padding-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
}
.price-box .items {
  font-weight: 600;
  font-size: 12px;
  color: #808285;
  padding: 5px 0;
}
.checkout-container .price-box .dishes {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
}
.items-price {
  display: flex;
}
.pay-box {
  display: flex;
  box-shadow: 0px 0px 20px #888;
  padding: 0 !important;
  /* margin-bottom: 68px;   */
}

.price-value {
  margin-left: auto;
}
.view-bill {
  width: 40%;
  text-transform: uppercase;
  background-color: #eee;
  padding: 15px 0 15px 20px;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #000;
}
.pay-bill {
  width: 60%;
  text-transform: uppercase;
  display: flex;
  background-color: #7d9a6f;
  color: #fff;
  padding: 15px 0px;
  font-weight: 800;
  font-size: 12px;
  line-height: 24px;
}
.pay-bill span {
  margin: auto;
}
.view-bill-link {
  font-weight: bold;
  font-size: 10px;
  line-height: 24px;
  color: #7d9a6f;
}
.m-auto {
  margin: auto !important;
}
.bottom-box {
  position: fixed;
  bottom: -2px;
  width: 100%;
}

.not-serviceble-badge {
  position: fixed;
  bottom: 65px;
  width: 100%;
  padding: 20px;
  color: #ff9800;
  background-color: #f8edd5;
  text-align: center;
}

.billing-section .menu-link span {
  vertical-align: super;
  margin-right: 10px;
}

.billing-section .menu-link {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  font-weight: 800;
  margin-right: 15px;
}

.select-address_container {
  display: flex;
  align-items: center;
  border: 1px solid #e1d8d8;
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: none;
  position: fixed;
}
.add-addres,
.select-address {
  text-align: center;
  width: 100%;
  padding: 25px;
  color: #7d9a6f;
  font-weight: bold;
  background:#fff;
}

.select-address {
  color: white;
  background-color: #7d9a6f;
}

