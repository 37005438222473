.search_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.download-pwa-btn {
  position: fixed;
  width: 100%;
  bottom: 2rem;
  padding-inline: 2rem;
}

.download-pwa-btn > button {
  width: 100%;
  outline: none !important;
  border: none !important;
  background: #670000;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
