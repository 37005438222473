.section-food-type {
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.section-food-type .food-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  list-style-type: none;
  align-items: center;
}
.section-food-type .food-list .food-list-item {
  margin-right: 22px;
}
.section-food-type .food-list .food-list-item:last-item {
  margin-right: 0px;
}
.section-food-type .food-list .food-list-item figure {
  margin-bottom: 0;
  text-align: center;
}
.section-food-type .food-list .food-list-item figure img {
  height: 18px;
  width: 18px;
}
.section-food-type .food-list .food-list-item figure figcaption {
  font-size: 6px;
  line-height: 0px;
  font-weight: 800;
}
/* .page-inner */
  .section-food-type
  .food-list
  .food-list-item.veg
  figure
  figcaption {
  color: #6a9902;
}
/* .page-inner */
  .section-food-type
  .food-list
  .food-list-item.egg
  figure
  figcaption {
  color: #eaa215;
}
/* .page-inner */
  .section-food-type
  .food-list
  .food-list-item.non-veg
  figure
  figcaption {
  color: #be360d;
}
.notactive.food-list-item{
  opacity: 0.5;
}
.Active.food-list-item{
    opacity: 1;
}

.pricing-info{
  text-decoration: underline;
}

.price-info-box {
  position: absolute;
  right: 0;
  top: 0;
  margin: 3.4rem 1.4rem 0 0;
  background-color: #fcf0d7;
  border-radius: .4rem;
  padding: .5rem;
  z-index: 9999;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-info-box > div{
  margin-bottom: .5rem;
  background: #FBF6EA;
  border-radius: .4rem;
  padding: 1rem 1.2rem;
}

.price-info-box h6{
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: .5rem;
  color: #670000 !important;
}

.price-info-box p{
  font-size: .9rem;
  color: #666666 !important;
}

.price-info-box ul{
  font-size: .9rem;
  color: #666666 !important;
  margin-bottom: 0;
}

.price-info-box > button{
  background-color: #670000;
  font-size: 1rem;
  color: white;
  border-radius: 100px;
  border: none;
  outline: none;
  padding: .8rem 1.6rem;
  width: fit-content;
}
