.about{
    margin: 3.5rem 0;
    padding: 2.5rem 1.5rem;
}

.about-item{
    margin-bottom: 1.5rem;
}

.about div>h6{
    color: #670000;
}

.about p{
    font-weight: normal;
    color: #595959;
}

.my{
    margin: 1.5rem 0;
}

.about h6{
    font-size: 1.2rem;
    font-weight: semibold;
    margin-bottom: .5rem;
    line-height: 1.5rem;
}

.about h5{
    margin: 1rem 0;
}