.Main-container {
    width: 100% !important;
    height: 100vh !important;
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
}

.top {
    flex: 40%;
    flex-wrap: wrap;
    

}
/* .top-map{
    height: 100px;
    width: 100%;
} */

.buttom { 
    flex: 50%;
    flex-wrap: wrap;
    padding: 25px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
box-shadow: 0px -12px 29px rgba(0, 0, 0, 0.25);
}

.info-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.info-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.info-right {
    display: flex;
    justify-content: center;
    align-items: center;
}
.span-inner-text{
    font-size: 18px;
    font-weight: 500;
    color:  #373737;
}
.span-inner-value{
    font-size: 16px;
    font-weight: 500;
    color:  #626262;
}
.Center-wrapper{
    display: flex;
    padding-top: 35px;

}
.cent-left{
    flex: 25%;
    flex-wrap: wrap;
    /* border: 1px solid red; */
    /* align-items: center; */
    display: flex;
    /* justify-content: center; */
    /* border: 1px solid red; */

}
.cent-right{
    flex: 75%;
    flex-wrap: wrap;
    display: flex;
    gap: 3rem;
    padding-top: 10px;
    margin-left: 10px;
    /* border: 1px solid green; */
}
.cent-text{
    display: flex;
    flex-direction: column;
}
.cent-text-status{
    font-size: 22px;
    font-weight: 600;
    color: #18172B;

}
.cent-text-status-snd{
    font-size: 18;
    font-weight: 400;  
    color: #626262;
}

.ul-tracker{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-right: 15px;
    /* border: 1px solid red; */
    /* margin-bottom: 3px; */
}
/* .ul-tracker-list{
    border: 1px solid red;
} */

.ul-tracker li{
    position: relative;
  
}
.ul-tracker li::after{
    content: "";
   position: absolute;
   height: 100%;
   width: 2px;
   left: 22px;
   background-color: #626262;
   
}
.ul-tracker li:last-child::after{
    content: none;
}
.logo-circle-fst{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #7D9A6F;
    display: flex;
    align-items: center;
    justify-content: center
}
.logo-circle-snd{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #FF7000;
    display: flex;
    align-items: center;
    justify-content: center
}
.list-img{
    height: 20px;
    width: 20px;
}

.buttom-container{
    flex: 10%;
    display: flex;
    padding: 10px;
    box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.25);
    /* border: 1px solid gray; */
    border-top: 1px solid lightgray;

}
.buttom-info{
    flex: 60%;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    align-items: center;
    /* justify-content: center; */
    margin-left: 12px;
    margin-top: 5px;
}
.info-details{
    display: flex;  
    flex-direction: column;
}
.span-text-name{
    font-size: 20px;
    font-weight: 600; 
    margin-bottom: 4px;
    letter-spacing: 1px;
}
.span-text-role{
    font-size: 16px;
    font-weight: 400; 
    margin-bottom: 4px;
    letter-spacing: 1px;
    opacity: 0.6;
}
.right-icon{
    flex: 40%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  
}
.right-icon-circle{

    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #FF7000;
    display: flex;
    align-items: center;
    justify-content: center

}