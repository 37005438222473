.auto_search_wrapper {
  display: flex;
  align-items: center;
  margin: 2rem;
}

.location-search_back-btn {
  margin: 0.4rem 1.5rem 0rem 0rem;
}

.pac-container {
  left: 0 !important;
  right: 0 !important;
  border-radius: 0.5rem;
  margin: 0.5rem 2rem;
  width: 85% !important;
  overflow: hidden;
}

.pac-container:after {
  content: none !important;
}

.pac-item-query {
  font-family: Nunito;
  word-wrap: break-word !important;
}

.pac-item {
  padding: 10px;
  /* margin-inline: 2rem; */
}

.location-search_input {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding-inline: 1rem;
  margin-inline: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 1rem;
}

.location-search_input > input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: normal;
}

.location-search_input > input {
  border: none !important;
  margin: 0 !important;
  font-weight: 600;
}
