.section-search-items {
    padding: 20px 20px 10px;
  }
  .section-search-items .row {
    margin-left: 0;
  }
  .section-search-items .row .col {
    padding-left: 0;
  }
  .section-search-items .input-group {
    height: 34px;
  }
  .section-search-items .input-group .append-item-left {
    top: 2px;
  }
  .section-search-items .input-group input.form-control {
    border-radius: 4.5rem;
    border: 1px solid #e8814d;
    color: #626262;
  }
  .section-search-items .input-group input:hover,
  .section-search-items .input-group input:focus {
    border: 1px solid #e8814d !important;
    color: #626262;
  }
  .section-search-items ::-webkit-input-placeholder {
    color: #626262 !important;
  }
  .section-search-items :-ms-input-placeholder {
    color: #626262 !important;
  }
  .section-search-items ::-ms-input-placeholder {
    color: #626262 !important;
  }
  .section-search-items ::placeholder {
    color: #626262 !important;
  }
  .section-search-items .btn-menu-bar {
    padding: 0;
    height: 32px;
    width: 32px;
  }