.alert_container {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert {
  padding: 1.2rem;
  background-color: white;
  width: 80%;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.alert_text {
  margin-block: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.alert_text h6 {
  font-weight: bold;
  font-size: 1.2rem;
}

.alert_text p {
  font-size: 1rem;
}

.alert-action-btns {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.alert-action-btns button {
  padding: 10px 18px;
  border-radius: 100px;
  background-color: #650000;
  color: white;
  border: 1px solid #650000;
}

.alert-action-btns button:last-child {
  background-color: transparent;
  color: #650000;
}
