.search-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style-type: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 50px;
	margin: 0;
	padding: 0;
	background: #F8EDD5;
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25)
}