.recent-searches_wrapper {
  margin: 2rem;
}

.recent-address_container {
  width: 100%;
  margin-left: 1rem;
}

.recent-searches_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 1rem;
  background: #f0f0f0;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.recent-address_container h6 {
  font-weight: bold;
  text-transform: capitalize;
  line-height: 2rem;
}
