.refer-container {
    width: 100%;
    padding-bottom: 50px;
}

.refer-container div.refer-top-bar {
    margin-left: 50px;
}
.refer-container div.refer-content {
    padding: 60px 20px 0;
}
.referral-code-messgae{
    font-size: 8px;
    display: block;
    float: right;
}

.btn-invite-friend{
    background: #670000 !important;
}

.btn-invite-friend p{
    font-size: 1rem !important;
}