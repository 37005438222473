.order-history_container {
  text-transform: capitalize;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 1.2rem;
}

.bd-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.8rem;
}

.bd-bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  color: #666666;
}

.order-history_right {
  display: flex;
  justify-content: start;
}

.order-history_left {
  margin-right: 1rem;
}

.order-history_left h1 {
  font-size: 16px;
  color: black;
  padding-bottom: 1rem;
}

.order-history_left p {
  font-size: 14px;
  color: #666666;
  padding-bottom: 0.5rem;
}

.order-history_schedule {
  display: flex;
  background-color: #670000;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  padding-inline: 15px;
}
.order-history_schedule img {
  width: 15px;
}

.order-history_btn-schedule {
  all: unset;
  color: white;
  margin-left: 0.5rem;
}
