.gps_wrapper {
  display: flex;
  justify-content: flex-start !important;
}

.gps_hr {
  margin-top: -6px;
}

.gps_content { 
  margin-left: 1rem;
}

.gps_content > h6 {
  font-weight: 600;
  font-size: 1rem;
}
.gps_content > p {
  font-weight: normal;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
