.splash-screen{
    width: 100%;
    height: 100vh;
    background-color: papayawhip;
}

.splash-screen img{
    width: 64px;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    transform: translateX(-50%);
    top: 50%;
    left: 50%;
}