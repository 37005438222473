.common-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  list-style-type: none;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: center;
  height: 70px;
  margin: 0;
  background: #f8edd5;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0px;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 550px;
  z-index: 10;
}
.common-nav .back-btn-wrapper {
  margin-left: 21px;
  margin-right: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
}
.common-nav .back-btn-wrapper .back-btn {
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  color: #626262;
}
.common-nav .back-btn-wrapper .back-btn img {
  height: 14px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  
}
.common-nav .nav-chef-detail .chef-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #626262;
  /* text-transform: capitalize; */
}
.common-nav .nav-chef-detail .chef-caption {
  font-size: 12px;
  line-height: 14px;
  color: #626262;
  font-weight: normal;
}
.common-nav .msg-btn-div {
  margin-left: auto;
  margin-right: 22px;
}
@media only screen and (max-width: 576px) {
  .common-nav .msg-btn-div {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 320px) {
  .common-nav .msg-btn-div {
    margin-right: 15px;
  }
}
.common-nav .msg-btn-div .btn-msg {
  background-color: transparent;
  border: 0px;
  width: 32px;
  height: 32px;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media only screen and (max-width: 576px) {
  .common-nav .msg-btn-div .btn-msg {
    width: 24px;
    height: 24px;
  }
}
