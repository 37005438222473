.page-inner {
  position: fixed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  z-index: 0;
  width: 100%;
  max-width: 550px;
  padding-bottom: 140px;
  height: 100vh;
  top: 65px;
}

.opaque{
  opacity: 0.5;
  pointer-events: none;
}

.brwose-menu-btn {
  margin-bottom: 80px;
}
