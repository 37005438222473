.location_btn-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.location_btn-container button {
  border: 1px solid #e8814d;
  box-sizing: "border-box";
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e8814d;
  font-size: 18px;
  font-weight: bold;
  background: transparent;
  min-width: 250px;
  padding: 20px 10px;
  margin: 20px auto 0px auto;
}
