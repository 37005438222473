.order-sucessfull-container{
    height: 100vh;
    background: #F8EDD5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.order-sucessfull-container p{
    margin: .4rem;
}

.order-sucessfull-container button{
    outline: none;
    border: none;
    margin: .4rem;
}

.order-sucessfull-container .btn-fill{
    padding: 1rem 2rem;
    background: #670000;
    color: #F8EDD5;
    border-radius: 100px;
}

.order-sucessfull-container .btn-text{
    background: none;
}